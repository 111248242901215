import React, { FunctionComponent, memo } from "react";
import styles from "../../styles.module.scss";
import VisitQuestion from "../VisitQuestion";
import {
  IQuestionItem,
  IVisitPosition,
} from "../../../../../../../utils/models";
import { TypeActivityType } from "../../../../../../../utils/types";
import { Col, Form, Row, Tooltip, Whisper } from "rsuite";
import IconSvg from "../../../../../../../global/atoms/IconHelper";
import { faAsterisk, faExclamation } from "@fortawesome/free-solid-svg-icons";
import VisitComment, { CommentsBadgeWrapper } from "../VisitComment";
import VisitReviewButton from "./VisitReviewButton";
import QuestionErrors from "./QuestionErrors";
import { isPublicView, isSuperAdmin } from "utils/helpers";
import { FormElementVisitEdit } from "utils/FormElement";
import { IVisitCommentItem } from "../../VisitEdit";
import VisitReviewShortButton from "./VisitReviewShortButton";
import SeparatorLine from "../../../../../../../global/atoms/separators/SeparatorLine";
import SeparatorEmpty from "../../../../../../../global/atoms/separators/SeparatorEmpty";

interface IQuestionBody {
  activityType: TypeActivityType;
  dependedOnQuestion?: IQuestionItem;
  question: IQuestionItem;
  item?: IVisitPosition;
  readOnly: boolean;
  hiddenElements: Array<string>;
  disabledElements: Array<string>;
  comments: IVisitCommentItem[];
  isDisabled: boolean;
  visitId?: string;
}

const QuestionBody: FunctionComponent<IQuestionBody> = memo(
  ({
    dependedOnQuestion,
    question,
    item,
    readOnly,
    activityType,
    hiddenElements,
    disabledElements,
    comments,
    visitId,
    isDisabled,
  }) => {
    const [commentsIsOpen, setCommentsIsOpen] = React.useState<boolean>(
      isPublicView()
    );
    const NameWrapped = () => {
      if (
        !readOnly &&
        (question.isRequired || question.dependOnQuestion.id !== "")
      ) {
        const tooltip = (
          <Tooltip>
            <div>{question.isRequired ? "Pytanie wymagane" : <></>}</div>
            <div>
              {question.dependOnQuestion.id ? (
                <>
                  {"Pytanie zależne od:"}
                  <br />
                  <strong>{dependedOnQuestion?.questionName}</strong>
                </>
              ) : (
                <></>
              )}
            </div>
          </Tooltip>
        );

        return (
          <Whisper placement={"bottomStart"} trigger="hover" speaker={tooltip}>
            <span style={{ fontSize: "1.1em" }}>
              {question.questionName}
              <div className={styles.qIconAttr}>
                {question.isRequired &&
                  IconSvg(faAsterisk, undefined, false, "red", { padding: 0 })}
                {question.dependOnQuestion.id !== "" &&
                  IconSvg(faExclamation, undefined, false, "orange", {
                    padding: 0,
                  })}
              </div>
            </span>
          </Whisper>
        );
      }

      return <span style={{ fontSize: "1.1em" }}>{question.questionName}</span>;
    };
    const isPhotoQuestion = question.questionType == "PHOTO_QUESTION";
    if (!question) return <></>;
    return (
      <div className={"rs-form-control-wrapper"}>
        <Form.Group>
          <Col xs={24}>
            <Col sm={12} xs={24} md={8}>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "space-between",
                }}>
                <div
                  className={styles.vqNameContainer}
                  title={isSuperAdmin() ? JSON.stringify(question) : undefined}>
                  <NameWrapped />
                </div>
                {activityType !== "LOCATION" && (
                  <>
                    <Whisper
                      placement={"bottomEnd"}
                      trigger={"hover"}
                      speaker={
                        <Tooltip>
                          Komentarze, rozwiń, aby dodać nowy komentarz
                        </Tooltip>
                      }>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setCommentsIsOpen(!commentsIsOpen)}>
                        <CommentsBadgeWrapper
                          len={
                            (
                              comments.filter(
                                (c) =>
                                  c.visitQuestionId === question.refQuestionId
                              ) ?? []
                            ).length
                          }
                        />
                      </div>
                    </Whisper>
                  </>
                )}
              </div>
              {isPhotoQuestion && (
                <>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <Col md={4} xs={4} sm={24}>
                      <VisitReviewButton
                        visitId={visitId ?? ""}
                        itemId={item?.itemId ?? ""}
                        activityType={activityType}
                        question={question}
                      />
                    </Col>
                    <Col md={12} xs={12} sm={24}>
                      {!hiddenElements.includes(
                        FormElementVisitEdit.photoQuestionShortReview
                      ) && (
                        <VisitReviewShortButton
                          visitId={visitId ?? ""}
                          itemId={item?.itemId ?? ""}
                          activityType={activityType}
                          question={question}
                        />
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
            <Col sm={12} xs={24} md={16}>
              <VisitQuestion
                question={question}
                isDisabled={isDisabled}
                key={`visitQuestion-${question.refQuestionId}`}
              />
            </Col>
            <QuestionErrors refQuestionId={question.refQuestionId} />
          </Col>

          {activityType !== "LOCATION" &&
            !hiddenElements.includes(FormElementVisitEdit.questionComment) &&
            commentsIsOpen && (
              <>
                <VisitComment
                  key={`visit-comment-${question.refQuestionId}`}
                  disabled={disabledElements.includes(
                    FormElementVisitEdit.questionComment
                  )}
                  refQuestionId={question.refQuestionId}
                  comments={comments.filter(
                    (c) => c.visitQuestionId === question.refQuestionId
                  )}
                />
              </>
            )}
          <SeparatorEmpty />
          <SeparatorLine />
        </Form.Group>
      </div>
    );
  }
);

export default QuestionBody;
